<template>
  <v-app>
    <v-app-bar app class="d-flex justify-center shadow m-0 p-0" color="white">
      <v-row>
        <v-col cols="12" class="px-0">
          <div class="search-bar d-flex p-relative">
            <v-text-field
              class=""
              placeholder="Searching For"
              filled
              rounded
              hide-details
              dense
              prepend-inner-icon="mdi-magnify"
            ></v-text-field>
          </div>
        </v-col>
      </v-row>
    </v-app-bar>

    <v-tabs
      class="mobile-tabs-categories"
      vertical
      show-arrows
      background-color="grey lighten-2"
      color="grey--text text--darken-1"
    >
      <v-tab class="d-flex flex-column" v-for="(n, index) in vTab" :key="index">
        <img v-svg-inline class="icon mb-2" :src="n.img" alt="" />
        <div
          class="
            mb-0
            text-11 text-capitalize text-truncate
            d-inline-block
            mw-70
          "
        >
          {{ n.name }}
        </div>
      </v-tab>

      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <h5 class="font-600 grey--text text--darken-4 mb-4">
              Recomended Categories
            </h5>
            <div class="d-flex flex-wrap">
              <div v-for="(n, index) in categories" :key="index" class="me-2">
                <router-link
                  to="/search-product"
                  class="
                    text-center text-decoration-none
                    grey--text
                    text--darken-4
                  "
                >
                  <v-avatar size="58" tile>
                    <img :src="n.img" alt="" />
                  </v-avatar>
                  <p class="text-11">{{ n.name }}</p>
                </router-link>
              </div>
            </div>
            <v-expansion-panels accordion flat class="mobile-expansion-panels">
              <v-expansion-panel v-for="(item, i) in mobileTabs" :key="i">
                <v-expansion-panel-header class="px-0 font-600">{{
                  item
                }}</v-expansion-panel-header>
                <v-expansion-panel-content class="mx-0 px-0">
                  <div class="d-flex flex-wrap">
                    <div
                      v-for="(n, index) in categories"
                      :key="index"
                      class="me-2"
                    >
                      <router-link
                        to="/search-product"
                        class="
                          text-center text-decoration-none
                          grey--text
                          text--darken-4
                        "
                      >
                        <v-avatar size="58" tile>
                          <img :src="n.img" alt="" />
                        </v-avatar>
                        <p class="text-11">{{ n.name }}</p>
                      </router-link>
                    </div>
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <h5 class="font-600 grey--text text--darken-4 mb-4">
              Recomended Categories
            </h5>
            <div class="d-flex flex-wrap">
              <div v-for="(n, index) in categories" :key="index" class="me-2">
                <router-link
                  to="/search-product"
                  class="
                    text-center text-decoration-none
                    grey--text
                    text--darken-4
                  "
                >
                  <v-avatar size="58" tile>
                    <img :src="n.img" alt="" />
                  </v-avatar>
                  <p class="text-11">{{ n.name }}</p>
                </router-link>
              </div>
            </div>
            <v-expansion-panels accordion flat class="mobile-expansion-panels">
              <v-expansion-panel v-for="(item, i) in mobileTabs" :key="i">
                <v-expansion-panel-header class="px-0 font-600">{{
                  item
                }}</v-expansion-panel-header>
                <v-expansion-panel-content class="mx-0 px-0">
                  <div class="d-flex flex-wrap">
                    <div
                      v-for="(n, index) in categories"
                      :key="index"
                      class="me-2"
                    >
                      <router-link
                        to="/search-product"
                        class="
                          text-center text-decoration-none
                          grey--text
                          text--darken-4
                        "
                      >
                        <v-avatar size="58" tile>
                          <img :src="n.img" alt="" />
                        </v-avatar>
                        <p class="text-11">{{ n.name }}</p>
                      </router-link>
                    </div>
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <h5 class="font-600 grey--text text--darken-4 mb-4">
              Recomended Categories
            </h5>
            <div class="d-flex flex-wrap">
              <div v-for="(n, index) in categories" :key="index" class="me-2">
                <router-link
                  to="/search-product"
                  class="
                    text-center text-decoration-none
                    grey--text
                    text--darken-4
                  "
                >
                  <v-avatar size="58" tile>
                    <img :src="n.img" alt="" />
                  </v-avatar>
                  <p class="text-11">{{ n.name }}</p>
                </router-link>
              </div>
            </div>
            <div class="d-flex flex-wrap">
              <router-link
                to="/search-product"
                class="
                  me-4
                  text-center text-decoration-none
                  grey--text
                  text--darken-4
                "
              >
                <v-avatar size="48" tile>
                  <img
                    v-svg-inline
                    class="icon"
                    src="@/assets/images/icons/man.svg"
                    alt=""
                  />
                </v-avatar>
                <p class="text-11">Man</p>
              </router-link>
              <router-link
                to="/search-product"
                class="
                  me-4
                  text-center text-decoration-none
                  grey--text
                  text--darken-4
                "
              >
                <v-avatar size="48" tile>
                  <img
                    v-svg-inline
                    class="icon"
                    src="@/assets/images/icons/woman.svg"
                    alt=""
                  />
                </v-avatar>
                <p class="text-11">Woman</p>
              </router-link>
              <router-link
                to="/search-product"
                class="
                  me-4
                  text-center text-decoration-none
                  grey--text
                  text--darken-4
                "
              >
                <v-avatar size="48" tile>
                  <img
                    v-svg-inline
                    class="icon"
                    src="@/assets/images/icons/baby-boy.svg"
                    alt=""
                  />
                </v-avatar>
                <p class="text-11">Baby Boy</p>
              </router-link>
              <router-link
                to="/search-product"
                class="
                  me-4
                  text-center text-decoration-none
                  grey--text
                  text--darken-4
                "
              >
                <v-avatar size="48" tile>
                  <img
                    v-svg-inline
                    class="icon"
                    src="@/assets/images/icons/baby-boy.svg"
                    alt=""
                  />
                </v-avatar>
                <p class="text-11">Baby Girl</p>
              </router-link>
            </div>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <h5 class="font-600 grey--text text--darken-4 mb-4">
              Recomended Categories
            </h5>
            <div class="d-flex flex-wrap">
              <div v-for="(n, index) in categories" :key="index" class="me-2">
                <router-link
                  to="/search-product"
                  class="
                    text-center text-decoration-none
                    grey--text
                    text--darken-4
                  "
                >
                  <v-avatar size="58" tile>
                    <img :src="n.img" alt="" />
                  </v-avatar>
                  <p class="text-11">{{ n.name }}</p>
                </router-link>
              </div>
            </div>
            <div class="d-flex flex-wrap">
              <router-link
                to="/search-product"
                class="
                  me-4
                  text-center text-decoration-none
                  grey--text
                  text--darken-4
                "
              >
                <v-avatar size="48" tile>
                  <img
                    v-svg-inline
                    class="icon"
                    src="@/assets/images/icons/man.svg"
                    alt=""
                  />
                </v-avatar>
                <p class="text-11">Man</p>
              </router-link>
              <router-link
                to="/search-product"
                class="
                  me-4
                  text-center text-decoration-none
                  grey--text
                  text--darken-4
                "
              >
                <v-avatar size="48" tile>
                  <img
                    v-svg-inline
                    class="icon"
                    src="@/assets/images/icons/woman.svg"
                    alt=""
                  />
                </v-avatar>
                <p class="text-11">Woman</p>
              </router-link>
              <router-link
                to="/search-product"
                class="
                  me-4
                  text-center text-decoration-none
                  grey--text
                  text--darken-4
                "
              >
                <v-avatar size="48" tile>
                  <img
                    v-svg-inline
                    class="icon"
                    src="@/assets/images/icons/baby-boy.svg"
                    alt=""
                  />
                </v-avatar>
                <p class="text-11">Baby Boy</p>
              </router-link>
              <router-link
                to="/search-product"
                class="
                  me-4
                  text-center text-decoration-none
                  grey--text
                  text--darken-4
                "
              >
                <v-avatar size="48" tile>
                  <img
                    v-svg-inline
                    class="icon"
                    src="@/assets/images/icons/baby-boy.svg"
                    alt=""
                  />
                </v-avatar>
                <p class="text-11">Baby Girl</p>
              </router-link>
            </div>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <h5 class="font-600 grey--text text--darken-4 mb-4">
              Recomended Categories
            </h5>
            <div class="d-flex flex-wrap">
              <div v-for="(n, index) in categories" :key="index" class="me-2">
                <router-link
                  to="/search-product"
                  class="
                    text-center text-decoration-none
                    grey--text
                    text--darken-4
                  "
                >
                  <v-avatar size="58" tile>
                    <img :src="n.img" alt="" />
                  </v-avatar>
                  <p class="text-11">{{ n.name }}</p>
                </router-link>
              </div>
            </div>
            <v-expansion-panels accordion flat class="mobile-expansion-panels">
              <v-expansion-panel v-for="(item, i) in mobileTabs" :key="i">
                <v-expansion-panel-header class="px-0 font-600">{{
                  item
                }}</v-expansion-panel-header>
                <v-expansion-panel-content class="mx-0 px-0">
                  <div class="d-flex flex-wrap">
                    <div
                      v-for="(n, index) in categories"
                      :key="index"
                      class="me-2"
                    >
                      <router-link
                        to="/search-product"
                        class="
                          text-center text-decoration-none
                          grey--text
                          text--darken-4
                        "
                      >
                        <v-avatar size="58" tile>
                          <img :src="n.img" alt="" />
                        </v-avatar>
                        <p class="text-11">{{ n.name }}</p>
                      </router-link>
                    </div>
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <h5 class="font-600 grey--text text--darken-4 mb-4">
              Recomended Categories
            </h5>
            <div class="d-flex flex-wrap">
              <div v-for="(n, index) in categories" :key="index" class="me-2">
                <router-link
                  to="/search-product"
                  class="
                    text-center text-decoration-none
                    grey--text
                    text--darken-4
                  "
                >
                  <v-avatar size="58" tile>
                    <img :src="n.img" alt="" />
                  </v-avatar>
                  <p class="text-11">{{ n.name }}</p>
                </router-link>
              </div>
            </div>
            <v-expansion-panels accordion flat class="mobile-expansion-panels">
              <v-expansion-panel v-for="(item, i) in mobileTabs" :key="i">
                <v-expansion-panel-header class="px-0 font-600">{{
                  item
                }}</v-expansion-panel-header>
                <v-expansion-panel-content class="mx-0 px-0">
                  <div class="d-flex flex-wrap">
                    <div
                      v-for="(n, index) in categories"
                      :key="index"
                      class="me-2"
                    >
                      <router-link
                        to="/search-product"
                        class="
                          text-center text-decoration-none
                          grey--text
                          text--darken-4
                        "
                      >
                        <v-avatar size="58" tile>
                          <img :src="n.img" alt="" />
                        </v-avatar>
                        <p class="text-11">{{ n.name }}</p>
                      </router-link>
                    </div>
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <h5 class="font-600 grey--text text--darken-4 mb-4">
              Recomended Categories
            </h5>
            <div class="d-flex flex-wrap">
              <div v-for="(n, index) in categories" :key="index" class="me-2">
                <router-link
                  to="/search-product"
                  class="
                    text-center text-decoration-none
                    grey--text
                    text--darken-4
                  "
                >
                  <v-avatar size="58" tile>
                    <img :src="n.img" alt="" />
                  </v-avatar>
                  <p class="text-11">{{ n.name }}</p>
                </router-link>
              </div>
            </div>
            <v-expansion-panels accordion flat class="mobile-expansion-panels">
              <v-expansion-panel v-for="(item, i) in mobileTabs" :key="i">
                <v-expansion-panel-header class="px-0 font-600">{{
                  item
                }}</v-expansion-panel-header>
                <v-expansion-panel-content class="mx-0 px-0">
                  <div class="d-flex flex-wrap">
                    <div
                      v-for="(n, index) in categories"
                      :key="index"
                      class="me-2"
                    >
                      <router-link
                        to="/search-product"
                        class="
                          text-center text-decoration-none
                          grey--text
                          text--darken-4
                        "
                      >
                        <v-avatar size="58" tile>
                          <img :src="n.img" alt="" />
                        </v-avatar>
                        <p class="text-11">{{ n.name }}</p>
                      </router-link>
                    </div>
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs>

    <mobile-navigation />
  </v-app>
</template>
<script>
import MobileNavigation from "@/layouts/base/MobileNavigationBar";
export default {
  components: {
    MobileNavigation,
  },
  data() {
    return {
      vTab: [
      
      ],
     categories:[],
      mobileTabs: [
        "Man Clothes",
        "Accessories",
        "Shoes",
        "Bags",
        "Woman Clothes",
        "Accessories",
        "Shoes",
        "Bags",
      ],
    };
  },
};
</script>
<style lang="scss">
.mobile-tabs-categories {
  height: calc(100vh - 112px);
  position: fixed;
  top: 56px;

  .v-slide-group__content {
    display: block;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .v-tabs-items {
    height: 100%;
    overflow: scroll;
    background-color: transparent !important;
  }
  .v-tab {
    height: 80px !important;
    width: 90px !important;

    border-bottom: 1px solid rgb(218, 225, 231);
  }
}
.mobile-expansion-panels {
  .v-expansion-panel-content__wrap {
    padding-right: 0;
    padding-left: 0;
  }
}
</style>
